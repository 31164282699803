import React, { useEffect, useState } from "react";
import { graphql } from 'gatsby';
import { Container, Row, Col } from "react-bootstrap";
import loadable from "@loadable/component";
import Layout from "../components/layout";
import { collectBreadcrum } from "../utils";

const BreadcrumbModule = loadable(() => import("../components/BreadcrumbModule/BreadcrumbModule"));
const CareerDetailIntro = loadable(() => import("../components/CareerDetailIntro/CareerDetailIntro"));
const CareerDetailDesc = loadable(() => import("../components/CareerDetailDesc/CareerDetailDesc"));
const CareerDetailSidebar = loadable(() => import("../components/CareerDetailSidebar/CareerDetailSidebar"));
const FooterContactMobile = loadable(() => import("../components/FooterContactMobile/FooterContactMobile"));

const CareerDetail = (props) => {

    const data = props?.data?.wpPost;

    // Sticky scroll
    const [scroll, setScroll] = useState(false)

    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 80)
        })
    },[])
    // Sticky scroll

    return (
        <Layout
            footertag={"career-contact"}
        >
            <div className="layout-padding-top"></div>

            <BreadcrumbModule data={collectBreadcrum(props?.pageContext?.data)}/>

            <div className="career-detail-wrapper">
                <Container>
                    <Row>
                        <Col xl={8}>
                            <CareerDetailIntro data={data} />

                            <CareerDetailDesc data={data} />
                        </Col>

                        <Col xl={1}></Col>

                        <Col xl={3} className="d-xl-block d-none">
                            <div className={`career-sidebar position-sticky ${scroll ? "scrolled" : ""}`}>
                                <CareerDetailSidebar data={data} />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className="d-xl-none">
                <FooterContactMobile 
                    tag="career-detail"
                />
            </div>
        </Layout>
    )
}

export const query = graphql`
  query ($page_id: String) {
      wpPost(id: {eq: $page_id}) {
        uri
        title
        status
        slug
        categories {
        nodes {
            name
        }
        }
        content
        featuredImage {
        node {
            sourceUrl
        }
        }
        ACF_careers {
            location
            salary
        }
      }
    }
`

export default CareerDetail