export const collectBreadcrum = (data) => {
    const categories = data?.categories?.nodes || [];
    let items = [];
  
    if (categories) {
        categories.forEach((category) => {
            if (category.parentId === null) {
                items = [
                    {
                        label: "Home",
                        link: '/'
                    },
                    {
                        label: category.name,
                        link: `/${category.slug}/`
                    },
                    {
                        label: data.title,
                        link: false
                    }
                ]
            }
        })
    }
    
    return items;
  }